import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{`Similar Portfolios`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-tr/"
            }}>{`All-Stars Total Return`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-market-vane/"
            }}>{`Market Vane`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: balanced growth`}</li>
        <li parentName="ul">{`Type: trend-following`}</li>
        <li parentName="ul">{`Invests in: ETFs tracking stocks and treasury bonds`}</li>
        <li parentName="ul">{`Rebalancing schedule: weekly with possible daily exit`}</li>
        <li parentName="ul">{`Taxation: 50% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $5,000`}</li>
      </ul>
      <p>{`TuringTrader’s `}<em parentName="p">{`Easy-Peasy`}</em>{` has two main goals: Outperformance of the balanced 60/40 benchmark on risk-adjusted metrics, and simplicity. To achieve these goals, the strategy combines our trusted Market Vane indicator with a simple bond strategy. The portfolio has overall docile behavior, with returns roughly on-par with the balanced 60/40 benchmark, but avoiding its prolonged downturns. In summary, this portfolio is a great fit for beginning investors or small accounts.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE3klEQVQ4y2VUWWyUVRS+0iclvhgSeSAQjFAopWWVJeDGYgqiDxIMZakiRkz0wQVZhAiVLcGWgAoFElpppzNTpmWGLpS2086/3HPvv8zSzkwLEqG+woviE/2n95jzUwyJD+ffcu73f+d83zlMSsEcR7J79zKMc14kBDBNM5kQsBQAjgJAFAAMANABIAIA+6WEEsPglMMAoIjyEZFZlmA+WDptM9Pkkzj3k8oBoBsAkIJzjob5vygYJg/ejJszXFswW0LR72nB6upijI2ODj4L9jEAjBMIACjOuScEeBlXFCbCmwikGErKv1KOqEjagrk2FOm6yfwynwHDCbAxzrkSAlQ8Yarz4X51IdyvfgnF1c/BOJ5rjqufGvvGapv6sC9hYsoR69Ku8MunCwMB5ZzzcZNzNDkv0F03uEo7Qp1q6EX25m/I1jYi29CEbGNATXq/Wb28LYxLvmzzot06WoI/7B0wpgIQQ8NkAqDbloCOBWOO5d8VvQ8lJbZ0arh2XxQ/r+3CVz+5huv2RTGeMLF3wPRzpIAx27ZRCFEnpWTMkrCUGLX36Cp6S1fRbl1d79aR/hzp0rD1poaWAPxz2MZFX7Tilup2/CNn+a2htnAAFQyGUNO0R0NDmWlsJGMdbYwlkFU0eWxDk3ruvQAFsk0BfHFLEKduD2N924AvwpTKMG4+0o75tMSEZqDruiocbsGy8iWFUCiMd+6MVLGMK6LhDg2nf9TirfkuqmbuuobzPovg+VA/NsUSVJrPkMp8YXMQP/zxCaCmmwSIuz/do6bPmOXV1JzBfD53jkQxBQAdKtzNWqriYAzX7Y/h/byN1CNS3rUAW7s0ZO8GsPJYB2aTAi3bwYaGqzi3ZIGaXVzqHTr0A+Zy2RABGtQP0+SFpC1U6Z4Ivr036peoG0+MnHYEXmkdQLa+EXee7MRcSiAXEmOxG3j8xCm1bPlq78DBw5jP55sJMDLRYI/UJTuQkqTw08mg5xP1vchWNvgMn/YwlUqqnp5eNb9ssVdTewZHRoZrCHA/AUoBHvXp+Q+C6p0DMRxMiv8AiSGZ+KWtITxw4ZbPPqHpmMlk1Fdf78XZxaWFtrbrePv2yBYmJcwjM5P/Il2aYpsCauP3BChRN0w0DANN00Tg3BeCnunb0NCgqq9vUHNLyse3Vu5A27Yf6HpiCqOtYZg8RCwutPSPsTVXccPBmMo4gNKyMJ1Oo+s6aNsWOo6NyWQSU6mUunjxklq+4nUsnlP2uKkpgNns0MnR0fvMX0GdcWPGYFL8/Wuon8bL2368HW9nU+rS5Svqm2/3IXmso6MTYzfa1cVLl9Xu3XtU+YKlOPOVOWPV1ccxnU7dbQ4GJgvBmT/QlgSamArqV3uPjvGEMZZ0XbWzahcdUouXrFArVr6hXlu2SpXOX0TfxktKFz4+Wn0MHcf5xzSN8mTSJXKTmKYZNMtFUvig620JDy0pMd7fj6tWv1Ugj82ZW+bNKi71ZhfP9xYuWlao3FaFjY0BnxmBpdMp5jhOkZQW8zdtWnImBRTZElhfwpzKAeoA+KOWlmt4+nQNkmkPHz6CtWfOYiTSipZlPchlsyebmwOTiRmBua7r68FsW7CzZ68zWo5UPvWBc2C2LaeNjAxXDefz52gCcrlscHg4X0PWIDVJAMqlMokZgdH2/xceF02SwJ+/igAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "TuringTrader.com, Easy-Peasy: A simple alternative to the 60/40",
                "title": "TuringTrader.com, Easy-Peasy: A simple alternative to the 60/40",
                "src": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
                "srcSet": ["/static/2b23f891a5d398e021f35a8c1073fc00/5a46d/logo-512x512.png 300w", "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><strong parentName="p">{` Learn More `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/dashboard/market-vane/"
            }}>{`Market Vane signal`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/heine-bond-model/"
            }}>{`Heine's Bond Model`}</a></li>
        </ul>
      </Aside>
      <p>{`The operation of `}<em parentName="p">{`Easy-Peasy`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`as `}<a parentName="li" {...{
            "href": "/portfolios/heine-bond-model/"
          }}>{`Heine's Bond Model`}</a>{` to switch between long-term and short-term treasury bonds.`}</li>
        <li parentName="ul">{`use `}<a parentName="li" {...{
            "href": "/dashboard/market-vane/"
          }}>{`Market Vane`}</a>{` to switch between a stock-market index and the managed bond sub-strategy.`}</li>
        <li parentName="ul">{`blend the two sub-strategies in a 60/40 fashion`}</li>
        <li parentName="ul">{`rebalance on a weekly schedule, unless Market Vane triggers an early exit`}</li>
      </ul>
      <p>{`With this mechanism, the portfolio combines the long-term stability of a strategic portfolio with the advantages of reducing drawdowns caused by market meltdowns or interest-rate hikes.`}</p>
      <p>{`The strategy trades only three ETFs representing the US stock market, intermediate-term US Treasury bonds, and the US money market. Almost all retirement plans should offer either the ETFs we have chosen, or funds that can act as drop-in replacements.`}</p>
      <p>{`Some retirement funds are restricted to trade only monthly. The portfolio reacts gracefully to delaying the trades to the end of the month, with only a small hit to its performance.`}</p>
      <h2>{`Diversification`}</h2>
      <p><em parentName="p">{`Easy-Peasy`}</em>{` typically invests 60% of its capital in the stock market, and 40% in long-term bonds. As a result, the strategy has the same level of diversification as the `}<a parentName="p" {...{
          "href": "/portfolios/classic-60-40/"
        }}>{`Classic 60/40`}</a>{`.`}</p>
      <p>{`On top of this, the strategy aims to avoid underperforming assets, leading to an overall more docile behavior and the ability to cope with a broader range of market regimes than the passive benchmark. However, `}<em parentName="p">{`Easy-Peasy`}</em>{`'s active management may lead to investing 100% in the bond market when the stock market's outlook is bearish.`}</p>
      <p>{`The Monte Carlo simulation illustrates the strategy's reduced risk profile, which can easily rival that of many more complicated strategies. The returns at the 5th percentile help investors achieve their critical financial goals sooner and with higher certainty.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p>{`In most years, `}<em parentName="p">{`Easy-Peasy`}</em>{` provides returns on-par with the 60/40 benchmark. However, when contemplating the full economic cycle, the strategy beats its benchmark by a significant margin.`}</p>
      <p>{`The rolling returns illustrate how `}<em parentName="p">{`Easy-Peasy`}</em>{` tracks its benchmark, and nicely improves periods where the benchmark underperforms or shows negative returns. The tracking chart supports these claims, showing an overall flat chart, with the outperformance mostly stemming from the 2008 recession.`}</p>
      <p>{`Overall, `}<em parentName="p">{`Easy-Peasy`}</em>{` delivers remarkably smooth returns for a portfolio of such simple construction, making it an easy choice for a wide range of financial objectives.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p>{`Even though `}<em parentName="p">{`Easy-Peasy`}</em>{` rebalances on a weekly schedule, it is relatively tax efficient. In typical bull-market periods, it will hold its exposure to the stock market throughout, resulting in a good probability of achieving long-term treatment of capital gains. In contrast to this, the strategy's bond market exposure is rotated relatively frequently. However, this is less of a concern, because interest payments are always taxed at the income tax rate. Nonetheless, the strategy works best in tax-deferred accounts.`}</p>
      <p>{`To allow for proper position sizing, `}<em parentName="p">{`Easy-Peasy`}</em>{` requires a minimum investment of $5,000.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, April 2023`}</a>{`: Initial release, combining `}<a parentName="li" {...{
            "href": "/portfolios/tt-market-vane/"
          }}>{`Market Vane`}</a>{`, and `}<a parentName="li" {...{
            "href": "/portfolios/heine-bond-model/"
          }}>{`Heine's Bond Model`}</a>{`.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      